import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { ResultsView } from "./ResultsView";
import { SetsExportView } from "./SetsExportView";
import { cloneDeep, map, mapValues } from "lodash";
import { usePrevious } from "./Various";
import { getRange } from "../utils";
import { useSettings } from "./SettingsProvider";
import { useUser } from "./UserProvider";
import { useUserData } from "./UserDataProvider";

export const MainState = ({ searchMode, showSetsExport }) => {
  const stdCategoryCount = 25;
  const {
    auth: { username },
  } = useUser();

  const {
    activeSet,
    collectionRange
  } = useUserData();

  const [searchParams, setSearchParams] = useSearchParams();

  // Search and suggestions
  const [searchValueState, setSearchValue] = useState("");
  const [searchCategoryState, setSearchCategory] = useState(undefined);
  
  const [searchTermsState, setSearchTerms] = useState([]);

  const [suggestionsState, setSuggestions] = useState({});
  const [suggestionsExpandedState, setSuggestionsExpanded] = useState([]);
  const [suggestedWords, setSuggestedWords] = useState([]);
  const [suggestionsExpansionState, setSuggestionsExpansion] = useState(false);

  // Filtering and results
  const [filtersState, setFilters] = useState({});
  const [commonState, setCommon] = useState([]);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [filterTermsState, setFilterTerms] = useState([]);
  const [resultsState, setResults] = useState([]);
  const [resultsRange, setResultsRange] = useState({ start: 0, end: 0 });
  const [resultCountState, setResultCount] = useState(0);

  const [categoriesState, setCategories] = useState({});
  const [categoriesExpandedState, setCategoriesExpanded] = useState([]);
  const [categoriesExpansionState, setCategoriesExpansion] = useState(false);

  const [searchHitCountState, setSearchHitCount] = useState(0);
  const [loadingState, setLoading] = useState(false);

  const searchTimeoutRef = useRef(null);
  const searchIdRef = useRef(0);
  const searchIdFetchedRef = useRef(-1);
  const selectIdRef = useRef(0);
  const selectIdFetchedRef = useRef(-1);

  const { settings } = useSettings();
  const prevLang = usePrevious(settings.lang);
  const prevSearchMode = usePrevious(searchMode);

  useEffect(() => {
    if(showSetsExport) {
      pushSearchParamsCollection();
    } else {
      if(searchMode=="meta") {
        pushSearchParamsSelect();
      } else {
        pushSearchParamsKey();
      }
    }
  }, [filtersState, filterTermsState, showSetsExport, activeSet, collectionRange, searchValueState, resultsRange]);

  const pushSearchParamsSelect = (
    filterIds=getFilterIds(),
    filterTerms=filterTermsState,
    range=resultsRange
  ) => {
    filterIds = map(filterIds);
    const params = new URLSearchParams([
      ...filterIds.map(x => ["id",x.join(",")]),
      ...filterTerms.map(x => ["term",x]),
      ["range", range.start + "-" + range.end]
    ])
    window.history.pushState({}, '', '?' + params.toString())
  }

  const pushSearchParamsCollection = () => {
    const params = new URLSearchParams([
      ["collection", activeSet],
      ["range", collectionRange.start + "-" + collectionRange.end]
    ])
    window.history.pushState({}, '', '?' + params.toString())
  }

  const pushSearchParamsKey = (
    key=searchValueState,
    range=resultsRange
  ) => {
    const params = new URLSearchParams([
      ["key", key],
      ["range", range.start + "-" + range.end]
    ])
    window.history.pushState({}, '', '?' + params.toString())
  }

  useEffect(() => {
    if (searchMode === "meta") {
      selectFetch();
    } else if (searchMode === "key") {
      searchKeyFetch({ key: "" });
    }
  }, [searchMode]);

  useEffect(() => {
    if (prevSearchMode) {
      setCategories({});
      setCategoriesExpanded([]);
      setCategoriesExpansion(false);

      setFilters({});
      setCommon([]);
      setDefaultFilters([]);
      setFilterTerms([]);
      setResults([]);
      setResultsRange({ start: 0, end: 0 });
      setResultCount(0);

      setSearchValue("");
      setSearchCategory(undefined);
      setSearchTerms([]);

      setSuggestions({});
      setSuggestionsExpanded([]);
      setSuggestedWords([]);
      setSuggestionsExpansion(false);
      setSearchHitCount(0);

      if (searchMode === "meta") {
        var filterIds = {};
        map(commonState, (x) => (filterIds[x.category] = [x.id]));
        selectFetch({ filterIds: filterIds });
      } else if (searchMode === "key") {
        searchKeyFetch({ key: "" });
      }
    }
  }, [searchMode]);

  useEffect(() => {
    const rangeString = searchParams.get("range")
    var range;
    if(rangeString) {
      let startEnd = searchParams.get("range").split("-").map(x => parseInt(x))
      range = {start: startEnd[0], end: startEnd[1]}
    }
    if (searchMode === "meta") {
      selectFetch({
        filterTerms: searchParams.getAll("term"),
        filterIds: searchParams.getAll("id").map(x => x.split(",").map(x => parseInt(x))),
        range: range
      });
    }
    else if (searchMode === "key") {
      const searchValue = searchParams.get("key");
      setSearchValue(searchValue)
      searchKeyFetch({
        key: searchValue,
        range: range
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (prevLang) {
      if (searchMode === "meta") {
        emptySearch();
        selectFetch();
      } else {
        searchKeyFetch();
      }
    }
  }, [settings.lang]);

  const getFilterIds = () => {
    return mapValues(filtersState, (items) => items.map((item) => item.id));
  };

  const emptySearch = () => {
    searchIdFetchedRef.current = searchIdRef.current;
    searchIdRef.current++;
    setSearchValue("");
    setSearchCategory(undefined);
    setSuggestions({});
    setSuggestionsExpanded([]);
    setSearchTerms([]);
    setSuggestedWords([]);
  };

  const removeSearchCategory = () => {
    // setSearchCategory(undefined)
    // searchMetaFetch(searchValueState, undefined, getFilterIds(), filterTermsState)
  };

  const selectCategory = (category) => {
    // if(searchMode === 'meta') {
    //   setSearchCategory(category)
    //   searchMetaFetch(searchValueState, category, getFilterIds(), filterTermsState)
    // } else {
    //   const searchValue = searchValueState + ' ' + category + ' '
    //   setSearchValue(searchValue)
    //   searchKeyFetch(searchValue)
    // }
  };

  const selectAll = (word) => {
    clearTimeout(searchTimeoutRef.current);
    const searchTerms = word ? computeSearchTerms(word) : computeSearchTerms(searchValueState);
    var filterTerms = filterTermsState.concat(searchTerms);
    emptySearch();
    selectFetch({ filterTerms: filterTerms });
  };

  const selectCategoryItem = (items) => {
    if (searchMode === "meta") {
      var filterIds = getFilterIds();
      map(items, (item) => {
        const category = item.category;
        if (category in filterIds) {
          filterIds[category].push(item.id);
        } else {
          filterIds[category] = [item.id];
        }
      });
      selectFetch({ filterIds: filterIds });
      emptySearch();
    } else {
      var filters = [...commonState, ...items];
      var searchValue = filters.map((e) => e.keyChunk).join(".");
      // if(!suggestion.last) {
      //   searchValue += '.'
      // }
      searchKeyFetch({ key: searchValue });
      setSearchValue(searchValue);
    }
  };

  const expandSuggestionsCategory = (category) => {
    const newExpanded = [category, ...suggestionsExpandedState];
    if (suggestionsState[category].results.length === 0) {
      if (searchMode === "meta") {
        searchMetaFetch({ categoriesExpanded: newExpanded, expansion: true });
      } else {
        searchKeyFetch({ categoriesExpanded: newExpanded, expansion: true });
      }
    } else {
      setSuggestionsExpanded(newExpanded);
    }
  };

  const expandCategoriesCategory = (category) => {
    const newExpanded = [category, ...categoriesExpandedState];
    if (categoriesState[category].results.length === 0) {
      selectFetch({
        categoriesExpanded: newExpanded,
        categoryOnly: category,
        expansion: true,
      });
    } else {
      setCategoriesExpanded(newExpanded);
    }
  };

  const reduceSuggestionsCategory = (category) => {
    const newExpanded = [...suggestionsExpandedState];
    newExpanded.splice(newExpanded.indexOf(category), 1);
    setSuggestionsExpanded(newExpanded);
  };

  const reduceCategoriesCategory = (category) => {
    const newExpanded = [...categoriesExpandedState];
    newExpanded.splice(newExpanded.indexOf(category), 1);
    setCategoriesExpanded(newExpanded);
  };

  const changeSearchValue = (searchValue) => {
    clearTimeout(searchTimeoutRef.current);
    setSearchValue(searchValue);
    if (searchMode === "meta") {
      if (searchValue === "") {
        emptySearch();
      } else {
        searchTimeoutRef.current = setTimeout(
          () => searchMetaFetch({ searchValue: searchValue }),
          100,
        );
      }
    } else {
      if (searchValue === "") {
        searchKeyFetch({ key: searchValue });
      } else {
        searchTimeoutRef.current = setTimeout(
          () => searchKeyFetch({ key: searchValue }),
          100,
        );
      }
    }
  };

  const computeSearchTerms = (searchValue) => {
    searchValue = searchValue.trim();
    // searchValue = searchValue.replace(/[.]/g, " ");
    var searchTerms = searchValue.split(" ");
    searchTerms = searchTerms[0] === "" ? [] : searchTerms;
    return searchTerms;
  };

  const selectFetch = ({
    filterIds = getFilterIds(),
    filterTerms = filterTermsState,
    range = { start: 0, end: 50 },
    categoryCount = 5,
    categoryOnly = null,
    seriesOnly = false,
    categoriesExpanded = [],
    expansion = false,
  } = {}) => {
    var data = {
      filterIds: map(filterIds),
      filterTerms: filterTerms,
      range: range,
      categoryCount: categoryCount,
      categoriesExpanded: categoriesExpanded,
      categoryOnly: categoryOnly,
      seriesOnly: seriesOnly,
      lang: settings.lang,
      username: username,
    };

    const selectId = selectIdRef.current;
    selectIdRef.current++;

    fetch(`${process.env.REACT_APP_API_URL}/metadata/select`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        if (selectId > selectIdFetchedRef.current) {
          selectIdFetchedRef.current = selectId;
          if (categoryOnly) {
            const categories = cloneDeep(categoriesState);
            categories[categoryOnly] = json.category;
            setCategories(categories);
            setCategoriesExpanded(categoriesExpanded);
            setCategoriesExpansion(expansion);
            return;
          }
          if (seriesOnly) {
            setResults(json.results);
            setResultsRange({
              start: range.start,
              end: range.start + json.results.length,
            });
            return;
          }
          delete json.categories.URL;
          setFilters(json.filters);
          setCommon(json.common.filter(x => x.category !== "URL"));
          setDefaultFilters(json.defaultFilters);
          setFilterTerms([...filterTerms]);
          setResults(json.results);
          setResultCount(json.resultCount);
          setResultsRange({
            start: range.start,
            end: range.start + json.results.length,
          });
          setSearchTerms([]);
          setCategories(json.categories);
          const catsExpanded =
            categoriesExpanded.length === 0 &&
            Object.keys(json.categories).length > 0
              ? [Object.keys(json.categories)[0]]
              : categoriesExpanded;
          setCategoriesExpanded(catsExpanded);
          setCategoriesExpansion(expansion);
        }
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const searchMetaFetch = ({
    searchValue = searchValueState,
    searchCategory = searchCategoryState,
    filterIds = getFilterIds(),
    filterTerms = filterTermsState,
    categoryCount = stdCategoryCount,
    categoriesExpanded = suggestionsExpandedState,
    expansion = false,
  } = {}) => {
    var searchTerms = computeSearchTerms(searchValue);

    const searchId = searchIdRef.current;
    searchIdRef.current++;

    setLoading(true);
    var data = {
      filterIds: map(filterIds),
      commonIds: commonState.map((x) => x.id),
      filterTerms: [...filterTerms, ...searchTerms.slice(0, searchTerms.length-1)],
      // filterTerms: filterTerms,
      searchTerms: searchTerms,
      categoryCount: categoryCount,
      categoriesExpanded: categoriesExpanded,
      searchCategory: searchCategory === undefined ? "" : searchCategory,
      lang: settings.lang,
      username: username,
    };
    fetch(`${process.env.REACT_APP_API_URL}/metadata/searchMeta`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        if (searchId > searchIdFetchedRef.current) {
          searchIdFetchedRef.current = searchId;
          var preWords = searchTerms.slice(0, searchTerms.length-1).join(' ');
          if(preWords != "") {
            preWords = preWords + " "
          }
          json.words.splice(4);
          json.words.map(x => x.word = preWords + x.word);
  
          delete json.suggestions.URL;
          setSearchTerms(searchTerms);
          setSuggestions(json.suggestions);
          setSuggestionsExpanded(Object.keys(json.suggestions));
          setSuggestionsExpansion(expansion);
          setLoading(false);
          setSearchHitCount(json.keyCount);
          setSuggestedWords(json.words);
        }
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const searchKeyFetch = ({
    key = searchValueState,
    range = { start: 0, end: 50 },
    categoryCount = 5,
    categoriesExpanded = [],
    seriesOnly = false,
    expansion = false,
  } = {}) => {
    const searchId = searchIdRef.current;
    searchIdRef.current++;

    setLoading(true);
    var data = {
      key: key,
      commonIds: commonState.map((e) => e.id),
      categoryCount: categoryCount,
      categoriesExpanded: categoriesExpanded,
      lang: settings.lang,
      seriesOnly: seriesOnly,
      range: range,
      username: username,
    };
    fetch(`${process.env.REACT_APP_API_URL}/metadata/searchKey`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        if (searchId > searchIdFetchedRef.current) {
          searchIdFetchedRef.current = searchId;
          if (seriesOnly) {
            setResults(json.results);
            setResultsRange({
              start: range.start,
              end: range.start + json.results.length,
            });
            return;
          }
          setCommon(json.common);
          setResults(json.results);
          setResultsRange({
            start: range.start,
            end: range.start + json.results.length,
          });
          setSearchTerms(json.searchTerms);
          setSuggestions(json.suggestions);
          // const catsExpanded = categoriesExpanded.length === 0 && Object.keys(json.suggestions).length > 0 ? Object.keys(json.suggestions) : categoriesExpanded;
          setSuggestionsExpanded(Object.keys(json.suggestions));
          setResultCount(json.resultCount);
          setSuggestionsExpansion(expansion);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log("Request failed", error);
      });
  };

  const removeFilter = (category, filter = undefined, add = false) => {
    var filterIds = getFilterIds();
    if (filter && filterIds[category].length > 1) {
      filterIds[category] = filterIds[category].filter((x) => x !== filter.id);
    } else {
      delete filterIds[category];
    }

    emptySearch();

    if (add) {
      selectFetch({ filterIds: filterIds, categoriesExpanded: [category] });
    } else {
      selectFetch({ filterIds: filterIds });
    }
  };

  const removeFilterTerm = (term) => {
    var filterTerms = filterTermsState.filter((x) => x !== term);
    emptySearch();
    selectFetch({ filterTerms: filterTerms });
  };

  const clearFilters = () => {
    emptySearch();
    selectFetch({ filterIds: {}, filterTerms: [] });
  };

  const setFilter = (content) => {
    emptySearch();
    var filterIds = {};
    content.meta
      .filter((x) => x.default === 0 && x.keyChunk !== "")
      .map((x) => (filterIds[x.category] = [x.id]));
    selectFetch({ filterIds: filterIds });
  };

  const loadResults = (type) => {
    const range = getRange(type, resultsRange, 50, resultCountState);
    if (searchMode === "meta") {
      selectFetch({ range: range, seriesOnly: true });
    } else {
      searchKeyFetch({ range: range, seriesOnly: true });
    }
  };

  if (showSetsExport) {
    return <SetsExportView onSetFilter={setFilter} />;
  }

  return (
    <ResultsView
      searchMode={searchMode}
      filterTerms={filterTermsState}
      filters={filtersState}
      common={commonState}
      defaultFilters={defaultFilters}
      results={resultsState}
      resultCount={resultCountState}
      resultsRange={resultsRange}
      suggestions={suggestionsState}
      suggestionsExpanded={suggestionsExpandedState}
      categories={categoriesState}
      categoriesExpanded={categoriesExpandedState}
      suggestedWords={suggestedWords}
      expandSuggestionsCategory={expandSuggestionsCategory}
      reduceSuggestionsCategory={reduceSuggestionsCategory}
      expandCategoriesCategory={expandCategoriesCategory}
      reduceCategoriesCategory={reduceCategoriesCategory}
      searchTerms={searchTermsState}
      searchCategory={searchCategoryState}
      searchValue={searchValueState}
      searchHitCount={searchHitCountState}
      suggestionsExpansion={suggestionsExpansionState}
      categoriesExpansion={categoriesExpansionState}
      onSetFilter={setFilter}
      selectAll={selectAll}
      selectCategory={selectCategory}
      selectCategoryItem={selectCategoryItem}
      removeSearchCategory={removeSearchCategory}
      changeSearchValue={changeSearchValue}
      removeFilter={removeFilter}
      removeFilterTerm={removeFilterTerm}
      clearFilters={clearFilters}
      loadResults={loadResults}
    />
  );
};
